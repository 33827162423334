import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class Samples extends Component {
  state = {
    activeSample: 0,
  };

  render() {
    const { activeSample } = this.state;
    const { title, subtitle, samples } = this.props;

    return (
      <Fragment>
        <section className="section section-samples">
          <div className="container">
            <div className="section-samples__content">
              <h2 className="section__title">{ title }</h2>
              <p className="section__subtitle">{ subtitle }</p>
              <ul className="section-samples__list list--clean">
                {[].slice.call(samples).map((sample, index) => (
                  <li key={sample.title}>
                    <button
                      type="button"
                      className={`section-samples__item${index === activeSample ? ' active' : ''}`}
                      onClick={() => this.setState({ activeSample: index })}
                    >
                      {sample.category}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="section-samples__viewer">
              {[].slice.call(samples).map((sample, index) => (
                <div key={sample.title} className={`sample${index === activeSample ? ' show' : ''}`}>
                  <div className="sample__title">{sample.title}</div>
                  <div className="sample__type">{sample.type}</div>
                  <div className="sample__description">{sample.description}</div>
                  <div className="sample__link">
                    <a href={sample.url} className="btn btn--green-fill" target="_blank" rel="noreferrer">View this sample</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

Samples.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  samples: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Samples.defaultProps = {
  title: 'Samples',
  subtitle: '',
};

export default Samples;
