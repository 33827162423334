// @todo enable next eslint rules
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Helmet from 'react-helmet';
import staticData from './index-data.json';
import Layout from '../components/Layout';

import imgYoungWomanWebp from '../img/pictures/img_young-woman.webp';
import imgYoungWomanPng from '../img/pictures/img_young-woman.png';
import imgSeniorThougthfulWebp from '../img/pictures/img_senior-thougthful.webp';
import imgSeniorThougthfulPng from '../img/pictures/img_senior-thougthful.png';

import './index.scss';

import Header from '../components/Header';
import Section from '../components/Section';
import Calculator from '../components/Calculator';
import Writers from '../components/Writers';
import Samples from '../components/Samples';
import Banner from '../components/Banner';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import Faq from '../components/Faq';

class IndexPage extends React.Component {
  componentDidMount() {
  }

  render() {
    const awSiteUrl = 'https://www.advancedwriters.com';

    return (
      <Layout className="IndexPage page-wrapper">
        <Helmet>
          <title>Essay Writing Service from Canadian Essay Writers | AdvancedWriters.com</title>
          <meta
            name="description"
            content="Canadian essay writers are waiting to provide you with high-quality academic assignments! Just enter our website, place an order, and get a custom-made paper for any of your disciplines in the shortest time possible!"
          />
          <link rel="canonical" href="https://ca.advancedwriters.com/" />
          <link rel="alternate" href="https://ca.advancedwriters.com/" hrefLang="en-ca" />
          <link rel="alternate" href="https://www.advancedwriters.com/" hrefLang="en-us" />
          <link rel="alternate" href="https://ca.advancedwriters.com/" hrefLang="x-default" />
        </Helmet>

        <Header />

        <Section className="section-hero-home">
          <div className="section-hero-home__content">
            <h1 className="section__title">Your reliable essay writing service with talented writers</h1>
            <p className="section__subtitle">Deal with any tasks in 75+ disciplines with the help of our Canadian essay
              writing service.</p>
            <div className="section__buttons">
              <a href={`${awSiteUrl}/dashboard/orders/create/`} rel="nofollow" className="btn btn--green-fill btn--with-arrow">Order
                now</a>
              <a href={`${awSiteUrl}/dashboard/inquiry/`} rel="nofollow" className="link--white">Get a free quote</a>
            </div>
          </div>
          <div className="section-hero-home__img">
            <picture>
              <source srcSet={imgYoungWomanWebp} type="image/webp" />
              <img src={imgYoungWomanPng} width={576} height={727} alt="" />
            </picture>
          </div>
        </Section>

        <Section className="section-calculator">
          <div className="section-calculator__calculator">
            <h2 className="section-calculator__heading">Play with deadline options to check the price</h2>
            <p className="section-calculator__subheading">Choose the discipline, deadline, academic level, and number of
              pages you need to see the price of your order.</p>

            <Calculator />
          </div>
          <div className="section-calculator__info">
            <div className="section-calculator__info-col">
              <div className="h4 section-calculator__info-title">The price includes:</div>
              <ul className="list--clean list--check-green">
                <li>Free title page and bibliography</li>
                <li>Formatting according to your instructions</li>
                <li>Amendments to the finished paper if they are needed</li>
              </ul>
            </div>
            <div className="section-calculator__info-col">
              <div className="h4 section-calculator__info-title">What else you can get:</div>
              <ul className="list--clean list--check-green">
                <li>Choice of 3 categories of writers</li>
                <li>Copies of the sources in the bibliography</li>
                <li>Charts, images, and PowerPoint slides</li>
              </ul>
            </div>
          </div>
        </Section>

        <LazyLoadComponent>
          <Section className="section-guarantees">
            <h2 className="h2 section__title">Guarantees we offer you</h2>
            <ul className="section-guarantees__list list--clean">
              <li className="section-guarantees__item ico__document--before">
                <div className="section-guarantees__item-title">No plagiarism</div>
                <div className="section-guarantees__item-text">We check every paper we create for uniqueness and only
                  send you pieces of writing with zero plagiarism in them.
                </div>
              </li>
              <li className="section-guarantees__item ico__circle-write--before">
                <div className="section-guarantees__item-title">Free revisions</div>
                <div className="section-guarantees__item-text">If you notify us that something from your primary
                  instructions is missing, we’ll revise your paper for free.
                </div>
              </li>
              <li className="section-guarantees__item ico__save-money--before">
                <div className="section-guarantees__item-title">Money-back guarantee</div>
                <div className="section-guarantees__item-text">We want to provide you with high-quality papers that
                  fully satisfy you, and if we cannot do that, we’ll give you a refund.
                </div>
              </li>
            </ul>
          </Section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <Writers
            title="Meet our experts"
            subtitle="Our company hires the best academic specialists through strict interviews, complex testing, and the requirement that they constantly expand their skills and knowledge. Thus, we have almost 500 talented writers from every part of the world, all of them highly proficient in English, experienced in writing, and narrowly specialized in couple of disciplines to provide you with homework help. They can deal with any task and will do it with pleasure, as the main task of these people is to help you study more efficiently and with less stress."
            writers={staticData.writers}
          />
        </LazyLoadComponent>

        <LazyLoadComponent>
          <Samples
            title="Review samples of our writers’ works"
            subtitle="To find out more about how our specialists do their jobs and see what you can get in the end, check out the samples we’ve prepared for you:
            "
            samples={staticData.samples}
          />
        </LazyLoadComponent>

        <Banner
          className="section-banner-black"
          title="Get a paper of extra quality written in the shortest terms"
        />

        <HowItWorks
          steps={staticData.howitworks}
        />

        <LazyLoadComponent>
          <Section className="section-benefits">
            <h2 className="section__title">Benefits: What we give you</h2>
            <p className="section__subtitle">For more than 15 years, we’ve been researching the needs of our customers.
              Thus, we know exactly what you’re looking for.</p>
            <ul className="section-benefits__list list--clean columns columns--col-3">
              <li className="section-benefits__item ico__support--before">
                <div className="section-benefits__item-title">24/7 customer service</div>
                <div className="section-benefits__item-text">Ask our support specialists any questions, and they will
                  help you.
                </div>
              </li>
              <li className="section-benefits__item ico__timer--before">
                <div className="section-benefits__item-title">Fast assistance</div>
                <div className="section-benefits__item-text">We can deal with your small tasks in 4+ hours.</div>
              </li>
              <li className="section-benefits__item ico__pig--before">
                <div className="section-benefits__item-title">Affordable prices</div>
                <div className="section-benefits__item-text">You can get cheaper prices if you place your order in
                  advance.
                </div>
              </li>
              <li className="section-benefits__item ico__confidentiality--before">
                <div className="section-benefits__item-title">Confidentiality</div>
                <div className="section-benefits__item-text">We don’t publicly disclose any of our customers' data.
                </div>
              </li>
              <li className="section-benefits__item ico__note-and-pen--before">
                <div className="section-benefits__item-title">Free formatting</div>
                <div className="section-benefits__item-text">We format your order for free so that it matches your
                  instructions.
                </div>
              </li>
              <li className="section-benefits__item ico__lists--before">
                <div className="section-benefits__item-title">75+ disciplines</div>
                <div className="section-benefits__item-text">Our experts can cover multidisciplinary topics too!</div>
              </li>
            </ul>
          </Section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <Testimonials
            title="Check out the latest reviews we got"
            subtitle="We’re highly rated on Sitejabber, and we look forward to getting your review as well! Be sure to check the feedback of other clients before you place your order."
            testimonials={staticData.testimonials}
          />
        </LazyLoadComponent>

        <Faq
          className="section-faq"
          title="Frequently asked questions"
          faq={staticData.faq}
        />

        <Section className="section-seo">
          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">Need a talented essay writer? We have plenty!</h2>
              <p>Each skill can be developed up to a certain level through constant practice. But to grow more
                efficiently, you often need the meticulous stare of a mentor who’ll provide you with valuable feedback.
                As you know, most teachers are going to concentrate on the mistakes related to your knowledge of the
                discipline, not those connected to writing specifically, though they matter too. So, if you want to deal
                with your education tasks faster and with less stress, you need one page or two of advanced essay
                writing to learn how to complete an original essay in any discipline.</p>
              <p>AdvancedWriters is the company that you should address when you want to find an essay writer online to
                help you with your academic tasks. Here you can buy any kind of paper and ask an expert to explain any
                topic to you. We specialize in writing assignments that you can get while studying in a high school in
                Toronto, a college in Montreal, the University of Ottawa, or any other educational institution in
                Canada. We’re open to all students’ requests and can deal with them efficiently.</p>
            </div>
          </div>

          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">The main purpose of our online essay writing service</h2>
              <p>Our main responsibility is to write papers of different kinds according to your instructions. As each
                task is researched by an expert, we’re not providing our essay service for free. Our specialist will
                spend hours on your task, editing and proofreading it till it gets an exemplary gloss. As an online
                paper writing service, we have nothing to hide in the way we work or what we offer to our customers, as
                our company is totally legit.</p>
              <p>We don’t use any previously written, poorly made, or plagiarism-infused essays. And still, we keep our
                prices affordable for most students. We, as a legitimate service, provide you with first-class writing
                to give you all the possible hints and examples for your studying. So use our materials to create even
                better papers with your own words, style, and means of expression. But until you become a master, you
                can rely on us for any questions, problems, or topics you have. We’re ready for your requests in more
                than 75 disciplines.</p>
            </div>
          </div>

          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">Canadian essay writers at your service</h2>
              <p>More than 500 writers are available to complete your custom orders at our website. Just send them any
                of your requests to deal with your homework more efficiently:</p>
              <ul className="list--clean list--circle-green">
                <li>We can <strong>finish your urgent tasks</strong> in 4+ hours, as we're not afraid of tight
                  deadlines. Even though not all our professional essay writers are Canadian, your task will get into
                  the hands of a person who deeply understands the context of your topic and environment.
                </li>
                <li>We can <strong>deal with any kind of paper</strong> you can get assigned in Canada: essays,
                  research, lab reports, coursework, dissertations, and reviews, to name a few. Choose the one you need
                  in the order form.
                </li>
                <li>Our writers <strong>narrowly specialize in 1-3 disciplines</strong>, so the quality of the delivered
                  papers is high, consisting of secondary research and summaries made of it. And even if you have a
                  multidisciplinary task, we’ll find someone very talented to help you.
                </li>
              </ul>
            </div>
          </div>

          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">The best essay writing service Canada can be proud of</h2>
              <p>The things that make us the best essay writing service are not limited to the papers we write. More
                than 15 years of experience in the market have taught us that every student is looking for more than
                just a trusted service, but for support and a positive attitude.</p>
              <p>Our clients are frequently tired and desperate, but extremely motivated people, who deserve not only an
                advanced online paper service but heartfelt support and comfort. Thus, our writers and customer support
                specialists are always kind and understanding in everything that’s connected to our clients. They know
                how hard studying can be when you really want to reach your goals in different spheres of life. With our
                service, you can always expect that:</p>
              <ul className="list--clean list--circle-green">
                <li>Your requirements will matter greatly to our writers.</li>
                <li>The assigned expert will do everything possible to deliver your paper before the deadline.</li>
                <li>The order will be formatted according to your demands.</li>
                <li>All our policies and guarantees will work for you in the same manner as they do for the rest of our
                  clients, and you can use them anytime.
                </li>
              </ul>
            </div>
          </div>

          <LazyLoadComponent>
            <div className="section-seo-block">
              <div className="section-seo-block__container">
                <h2 className="section__title">Other services you can get from AdvancedWriters.com</h2>
                <p>Aside from getting an essay writer Canada would be proud of, you can order some additional services
                  from our company:</p>
                <ul className="list--clean section-seo-list--writing-service">
                  <li className="ico__certificate--before"><strong>The Smart paper option.</strong> This feature enables
                    additional comments to be made by your expert on the key points of your essay. You’ll understand how
                    the parts of your paper are interconnected and how the logic of an essay should be displayed.
                  </li>
                  <li className="ico__copies--before"><strong>Copies of sources.</strong> You’ll get an additional file
                    with excerpts from the literature sources that were used for your paper to go deeper into the
                    context of the research.
                  </li>
                  <li className="ico__chart--before"><strong>Charts and slides.</strong> If you choose this option,
                    we’ll add custom-made charts representing the main points of your project. Or we can prepare some
                    slides for you to present the summary and main focus of your paper. Just let us know!
                  </li>
                  <li className="ico__edit--before"><strong>Editing and proofreading.</strong> We can edit or proofread
                    any completed paper you send to us, format it properly, and make it shine for you!
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoadComponent>

          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">How to learn from the papers we create for you</h2>
              <ol className="list--numeric list--clean">
                <li><strong>Use them as examples to follow.</strong> Once you learn how to mimic the structure, connect
                  blocks of information, and make conclusions, you’ll get one step closer to understanding this whole
                  “essay writing” thing.
                </li>
                <li><strong>Get another point of view.</strong> If you know your topic from top to bottom, get the help
                  of a professional writer who will surely find a perspective on your problem that you won’t expect.
                  Widen your research this way and extend it in various directions.
                </li>
                <li><strong>Find mistakes that are hidden from you.</strong> When you’re writing for a long time, your
                  eye is constantly scanning the same text, and it becomes blind to the errors hidden therein. Our
                  experts will help you to cut those mistakes out and improve the overall quality of your paper.
                </li>
              </ol>
            </div>
          </div>
        </Section>

        <Section className="section-seo-with-image">
          <div className="section-seo-with-image__container">
            <h2 className="section__title">Custom essay writing service: How we “customize” your papers</h2>
            <div className="section-seo__img">
              <LazyLoadComponent>
                <picture>
                  <source srcSet={imgSeniorThougthfulWebp} type="image/webp" />
                  <img src={imgSeniorThougthfulPng} width={582} height={709} alt="" />
                </picture>
              </LazyLoadComponent>
            </div>
            <p>You can address our writers for professional help with any of your academic tasks because they adhere to
              a special process of customizing your papers:</p>
            <ol className="list--numeric list--clean">
              <li>Each expert <strong>starts with a blank page</strong>. Even if he/she has already seen this task a
                hundred times before, they start from the very beginning every time. Our custom essay writing company
                insists on such an approach because the devil always hides in the details that differentiate one task
                from another. So we start with great attention to these special requirements to provide you with the
                highest quality of the completed paper.
              </li>
              <li>Each writer is <strong>responsible for the originality</strong> of the final version of a paper. We
                check our essays twice before submitting them to our customers. Our writers are 100% reliable, and don’t
                need to worry about plagiarism anymore.
              </li>
              <li>We’re <strong>ready for your edits</strong>: our free revision policy assumes that you can send us
                edit requests that are aligned with your primary requirements, in which case we’ll do them for free. Or
                if you have another idea about how to improve your paper in the course of it being written, you can
                address our support to add some more requirements to your order. We’re ready to satisfy any of your
                writing-related whims.
              </li>
            </ol>
          </div>
        </Section>

        <Section className="section-seo">
          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">Custom writing: Reasons to try our services</h2>
              <p>“Why should I get someone to write my essay for me?” Well, the reasons for that are really diverse.
                Firstly, many people have to do a lot of work. Secondly, they may be too overwhelmed by the overall scope
                of the tasks being given. Negative situations occur in all of our lives. Thus, getting a helper to provide
                professional samples for your assignment is a great idea. In this way, you will be able to get personal
                custom writing help that will become the path to upgrading your skills. With our <a href={awSiteUrl}>
                  professional essay writing service</a>, you are not simply getting someone random to assist you. Instead,
                true professionals will come to your aid.</p>
            </div>
          </div>
          <div className="section-seo-block">
            <div className="section-seo-block__container">
              <h2 className="section__title">Essay service: A smart option for smart students</h2>
              <p>Our essay services are one of the most student-oriented among the different websites. Above, you can see
                the ways of customizing your paper demands. You can adjust the price of your paper to buy something
                genuinely cheap and pay a low price for the best service. Ultimately, you also have the option of
                communicating with our key specialists. In this respect, our customers can provide not only some general
                instructions but something very detailed. Just provide a good description of your task, and our essay
                helper will be there to assist. More importantly, you can communicate with the writer to decide on the
                topic for essay writing help if it is unclear, for example. Adding some instructions to the order is also
                easy. With our cheap essay writing service, all it takes is writing one small message: “So, can you write
                my paper for me? Can you do my essay?” Yes, our essay service is superb. Try one of the most outstanding
                essay writing websites today and enjoy the assistance of truly smart experts.</p>
            </div>
          </div>
        </Section>

        <Banner
          className="section-banner-green"
          title="Get a custom paper on your topic to become confident about your studies"
        />
      </Layout>
    );
  }
}

export default IndexPage;
